import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Grid, ConditionalWrapper } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackGA4Event, trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';
import { getLinkHostname } from '@gumtree/shared/src/util/ga4-bff';

import AppStoreBadge from './app-store-badge.svg';
import GooglePlayBadge from './google-play-badge.svg';

import { FooterLinksDownload, FooterLinksItemStyle } from './footer-links-style';
import { androidNames, iOSNames } from './footer-app-links';

const getImageComponent = (image) => {
    switch (image) {
        case 'app-store-badge':
            return (
                <FooterLinksDownload data-testid="app-store-image">
                    <AppStoreBadge />
                </FooterLinksDownload>
            );
        case 'google-play-badge':
            return (
                <FooterLinksDownload data-testid="google-play-image">
                    <GooglePlayBadge />
                </FooterLinksDownload>
            );
        default:
            return null;
    }
};

const mobileNames = ([] as string[]).concat(iOSNames, androidNames);

const FooterLink: React.FC<Props> = ({ image, label, link, eventAction, eventLabel, name }) => {
    const handleClick = useCallback(
        async (event: React.MouseEvent) => {
            event.preventDefault();
            
            if (mobileNames.includes(name)) {
                trackGA4Event<GA4.ClickFooter>({
                    event: 'click_footer',
                    linkDomain: getLinkHostname(link),
                    linkHeader: "footer",
                    linkText: label,
                    linkType: 'app link', // external
                    linkURL: link,
                });
                window.open(link, '_blank');
            } else {
                trackGA4PreNav<GA4.ClickFooter>({
                    event: 'click_footer',
                    linkDomain: getLinkHostname(link),
                    linkHeader: "footer",
                    linkText: label,
                    linkType: 'text',
                    linkURL: link,
                });
                window.location.href = link;
            }
        },
        [eventAction, link, eventLabel]
    );

    return (
        <ConditionalWrapper
            condition={image}
            wrapper={(children) => (
                <Grid colXl={6} style={{ float: 'left' }}>
                    {children}
                </Grid>
            )}
        >
            <a
                css={FooterLinksItemStyle}
                href={link}
                onClick={handleClick}
                data-testid="footer-links-item"
                {...qaAttribute('footer-links-item')}
            >
                <span className={classnames({ 'hide-visually': image })}>{label}</span>
                {getImageComponent(image)}
            </a>
        </ConditionalWrapper>
    );
};

interface Props {
    eventAction: string;
    eventLabel: string;
    image: string;
    label: string;
    link: string;
    name: string;
}

FooterLink.defaultProps = {
    eventAction: undefined,
    eventLabel: '',
    image: '',
    label: '',
    link: '',
};

export default FooterLink;
