import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Icon } from '@gumtree/ui-library';
import FormElement from '@gumtree/ui-library/src/form/form-element';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { sanitizeString } from '@gumtree/ui-library/src/utils/string-service';
import { trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';

import { Device } from '@gumtree/shared/src/types/client-data';
import Input from '@gumtree/ui-library/src/form/input';
import { getDistanceFromUserPref } from '@gumtree/shared/src/util/user-pref-cookie-service';
import { GrowthBookFeature } from '@gumtree/shared/src/model/experiment';
import { getGrowthbookClientSide } from '@gumtree/shell/src/growthbook/init-growthbook';

import KeywordSearchField from './keyword-search-field/keyword-search-field-container';
import LocationField from './location-field/location-field-container';
import { setupSuggestionsService, submitSearch } from './search-bar-service';
import appBannerCookieStorage from './app-banner-cookie-storage';
import './opensearch.xml';
import { toggleSearchModal } from './search-bar-actions';
import { SearchModal } from './mobile-modal/modal';
import { OnlyShowOnLargeScreen, OnlyShowOnSmallScreen } from '../utils/screens.style';
import { searchFieldPlaceholderText } from './constants';
import type { SearchBarKeywordSelection, SearchBarKeywordState } from './reducers/keyword-reducer';
import type {
    SearchBarLocationSelection,
    SearchBarLocationState,
} from './reducers/location-reducer';
import { searchBarContainerCss } from './search-bar.style';
import { TrackingUtil } from './tracking-util';
import type { G5SParams } from './reducers/g5s-params';

interface SearchBarProps {
    device: Device | undefined;
    popularSearches: {
        showPopularSearches: boolean;
        queryKeywords?: Array<{ name: string; url: string }>;
    };
    isQA?: boolean;
    algoliaKey: string;
    keyword?: SearchBarKeywordState;
    selectedKeyword?: SearchBarKeywordSelection;
    selectedLocation?: SearchBarLocationSelection;
    location?: SearchBarLocationState;
    showSearchModal: boolean;
    showSearchBarOnMobile: boolean;
    outsideFocus?: boolean;
    g5SParams: G5SParams;
}

export const submitSearchHandler = async ({
    setIsSearchSubmitted,
    currentKeyword,
    selectedKeyword,
    selectedLocation,
    currentLocation,
    requireKeywordCorrection = false,
    keywordPopulatedBy,
    locationPopulatedBy,
    keywordSuggestions,
    keywordSuggestionsLatency,
    keywordSuggestionsLatencyGroup,
    isUsing58SearchSuggestion,
}: {
    setIsSearchSubmitted: (value: boolean) => void;
    currentKeyword: string | undefined;
    selectedKeyword: SearchBarKeywordSelection | undefined;
    selectedLocation: SearchBarLocationSelection | undefined;
    currentLocation: string | undefined;
    requireKeywordCorrection: boolean;
    keywordPopulatedBy: GA4.SubmitListingSearchEvent['search']['keywordPopulatedBy'];
    locationPopulatedBy: GA4.SubmitListingSearchEvent['search']['locationPopulatedBy'];
    keywordSuggestions: string[];
    keywordSuggestionsLatency?: number | undefined;
    keywordSuggestionsLatencyGroup?: string | undefined;
    isUsing58SearchSuggestion: boolean;
}) => {
    setIsSearchSubmitted(true);
    const submittableKeyword = selectedKeyword?.name ? selectedKeyword?.name : currentKeyword;
    const submittableLocation = selectedLocation?.name ? selectedLocation?.name : currentLocation;

    const sanitizedKeyword = submittableKeyword
        ? sanitizeString(submittableKeyword)
        : submittableKeyword;
    const sanitizedLocation = submittableLocation
        ? sanitizeString(submittableLocation)
        : submittableLocation;

    trackGA4PreNav<GA4.SubmitListingSearchEvent>({
        event: 'submit_listing_search',
        search: {
            locationPopulatedBy,
            locationInput: currentLocation,
            locationSelected: selectedLocation?.name || currentLocation,
            keywordPopulatedBy,
            keywordInput: currentKeyword,
            keywordSelected: selectedKeyword?.name,
            keywordSuggestions: keywordSuggestions.join(','),
            keywordSuggestionsVolume: keywordSuggestions.length,
            keywordInputCharCount: currentKeyword?.length,
            keywordSuggestionsLatency,
            keywordSuggestionsLatencyGroup,
            keywordSuggestionsProvider: isUsing58SearchSuggestion ? '58_search' : 'algolia',
        },
    });

    const distance = getDistanceFromUserPref();

    submitSearch({
        category: selectedKeyword?.category,
        categoryDisplayName: selectedKeyword?.categoryDisplayName,
        keyword: sanitizedKeyword,
        location: sanitizedLocation,
        requireKeywordCorrection,
        searchOptionsExactMatch: Boolean(selectedKeyword?.searchOptionsExactMatch),
        additionalParams: {
            ...(distance ? { distance } : {}),
        },
        keywordPopulatedBy,
    });
    appBannerCookieStorage();
};

const SearchBar: React.FC<SearchBarProps> = ({
    algoliaKey,
    showSearchModal,
    isQA = false,
    keyword,
    location,
    popularSearches,
    showSearchBarOnMobile,
    outsideFocus,
    g5SParams,
}) => {
    const dispatch = useDispatch();

    const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

    const handleToggleSearchDialog = () => {
        dispatch(toggleSearchModal());
    };

    useEffect(() => {
        const isUseG5S =
            !!getGrowthbookClientSide() && getGrowthbookClientSide().isOn(GrowthBookFeature.G5S);

        setupSuggestionsService(isQA, algoliaKey, isUseG5S, g5SParams);
    }, [isQA, algoliaKey]);

    useEffect(() => {
        // upon selecting a suggestion
        if (keyword?.selectedSuggestion || location?.selectedSuggestion) {
            const keywordPopulatedBy = TrackingUtil.getPopulatedBy(keyword);
            const keywordSuggestionsLatency = TrackingUtil.getKeywordSuggestionsLatency(
                keywordPopulatedBy,
                keyword
            );
            const isUsing58SearchSuggestion =
                !!getGrowthbookClientSide() &&
                getGrowthbookClientSide().isOn(GrowthBookFeature.G5S);

            submitSearchHandler({
                setIsSearchSubmitted,
                currentKeyword: keyword?.value,
                selectedKeyword: keyword?.selectedSuggestion,
                selectedLocation: location?.selectedSuggestion,
                currentLocation: location?.value,
                keywordPopulatedBy,
                locationPopulatedBy: TrackingUtil.getPopulatedBy(location),
                keywordSuggestions: TrackingUtil.getKeywordSuggestions(keywordPopulatedBy, keyword),
                requireKeywordCorrection: false,
                keywordSuggestionsLatency,
                keywordSuggestionsLatencyGroup: keywordSuggestionsLatency
                    ? TrackingUtil.getLatencyCategory(keywordSuggestionsLatency)
                    : undefined,
                isUsing58SearchSuggestion,
            });
        }
    }, [keyword?.selectedSuggestion, location?.selectedSuggestion]);

    return (
        <div className="search-bar-container" css={searchBarContainerCss}>
            <Form
                action="/search"
                onSubmit={() => {
                    submitSearchHandler({
                        setIsSearchSubmitted,
                        currentKeyword: keyword?.value,
                        selectedKeyword: keyword?.selectedSuggestion,
                        selectedLocation: location?.selectedSuggestion,
                        currentLocation: location?.value,
                        keywordPopulatedBy: TrackingUtil.getPopulatedBy(keyword, true),
                        locationPopulatedBy: TrackingUtil.getPopulatedBy(location, true),
                        keywordSuggestions: [],
                        requireKeywordCorrection: true,
                        isUsing58SearchSuggestion:
                            !!getGrowthbookClientSide() &&
                            getGrowthbookClientSide().isOn(GrowthBookFeature.G5S),
                    });
                }}
                data-testid="submit-form"
            >
                <div className="search-bar" {...qaAttribute('search-bar')}>
                    <SearchModal isOpen={showSearchModal} onClose={handleToggleSearchDialog} />

                    {showSearchBarOnMobile && (
                        <OnlyShowOnSmallScreen>
                            <div className="inputs-container--fake-search">
                                <FormElement type="input" className="input-field">
                                    <div className="icon-container">
                                        <Icon type="magnifying-glass" size="medium" />
                                    </div>
                                    <Input
                                        placeholder={searchFieldPlaceholderText}
                                        autoComplete="off"
                                        className="input"
                                        id="search-bar-input"
                                        name="search-bar-input"
                                        onClick={handleToggleSearchDialog}
                                        value={keyword?.value || ''}
                                    />
                                </FormElement>
                            </div>
                        </OnlyShowOnSmallScreen>
                    )}

                    <OnlyShowOnLargeScreen>
                        <div className="inputs-container--real-search">
                            <div className="keyword-search-container">
                                <KeywordSearchField outsideFocus={outsideFocus} />
                            </div>
                            <div className="dividing-line" />
                            <div className="location-container">
                                <LocationField />
                                <Button
                                    className="search-button"
                                    display="primary"
                                    isLoading={isSearchSubmitted}
                                    loadingText=""
                                    label={<Icon type="magnifying-glass" />}
                                    type="submit"
                                    {...qaAttribute('search-button')}
                                />
                            </div>
                        </div>
                    </OnlyShowOnLargeScreen>

                    {popularSearches.showPopularSearches && popularSearches.queryKeywords && (
                        <div className="popular-search-wrapper">
                            <input type="checkbox" id="popular-search-container-input" hidden />
                            <ul className="popular-search-container">
                                <li className="popular-search-container-title">Top searches: </li>
                                {popularSearches.queryKeywords.map((el) => (
                                    <li key={el.name}>
                                        <a href={el.url}>{el.name}</a>
                                    </li>
                                ))}
                            </ul>
                            {}
                            <label
                                htmlFor="popular-search-container-input"
                                className="expand-label"
                            >
                                <Icon type="dropdown" />
                            </label>
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default SearchBar;
