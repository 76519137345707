import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { getHasUserMadeChoice } from './util';

const trackingEventsOnOneTrust = async (window) => {
    await getHasUserMadeChoice(window); // wait for banner load

    const preferenceOptionsLookup: Record<string, GA4.PrivacyPrefKey> = {
        'Functional Cookies': 'functionalCookie',
        'Performance Cookies': 'performanceCookie',
        'Targeting Cookies': 'targetingCookie',
        'Google Advertising': 'googleAdvertising',
        'Adobe Marketplace': 'adobeMarketplace',
        'Microsoft Clarity': 'microsoftClarity',
        TikTok: 'tiktok',
        'TV Squared': 'tvSquared',
        'Google ATPs': 'googleAtps',
        Qualaroo: 'qualaroo',
        'Facebook Advertising': 'facebookAdvertising',
        LiveRamp: 'liveramp',
        'Store and/or access information on a device': 'storeAccessDeviceData',
        'Personalised advertising and content, advertising and content measurement, audience research and services development':
            'personalisationData',
        'Use precise geolocation data': 'geolocationData',
        'Actively scan device characteristics for identification': 'deviceIdentificationData',
    };

    const initiatePreferenceOptions = () => {
        const preferenceOptions = {} as Partial<Record<GA4.PrivacyPrefKey, boolean>>;
        const toggles = window.document.querySelectorAll('.ot-tgl input[type="checkbox"]');

        toggles.forEach((toggle) => {
            const label = toggle.closest('.ot-tgl')?.querySelector('.ot-label-txt');
            const labelValue = label?.textContent.trim();

            if (labelValue && preferenceOptionsLookup[labelValue]) {
                preferenceOptions[preferenceOptionsLookup[labelValue]] = toggle.checked;
            }
        });

        return preferenceOptions;
    };

    const resetPrivacyPrefObj = () => {
        trackGA4Event<GA4.UpdatePrivacyPreferencesEvent>({
            event: 'update_privacy_preferences',
            privacyPref: undefined,
        });
    };

    window.document.body.addEventListener('click', async (event) => {
        const oneTrustUI = window.document.querySelector('#onetrust-consent-sdk');
        const isClickInsideOneTrustUI = oneTrustUI && oneTrustUI.contains(event?.target);
        const defaultPreferenceOptions = initiatePreferenceOptions();

        if (isClickInsideOneTrustUI) {
            const bannerAcceptBtn = document.querySelector('#onetrust-accept-btn-handler');
            const bannerRejectBtn = document.querySelector('#onetrust-reject-all-handler');
            const bannerManageBtn = document.querySelector('#onetrust-pc-btn-handler');
            const preferenceAcceptBtn = document.querySelector('#accept-recommended-btn-handler');
            const preferenceRejectBtn = document.querySelector('.ot-pc-refuse-all-handler');
            const preferenceSaveBtn = document.querySelector('.save-preference-btn-handler');
            const toggledNob = event.target.closest('.ot-switch-nob');

            if (bannerAcceptBtn?.contains(event?.target)) {
                trackGA4Event<GA4.ConsentBannerEvent>({
                    event: 'consent_banner',
                    clickConsentBanner: 'accept',
                });
            }

            if (bannerRejectBtn?.contains(event?.target)) {
                trackGA4Event<GA4.ConsentBannerEvent>({
                    event: 'consent_banner',
                    clickConsentBanner: 'reject',
                });
            }

            if (bannerManageBtn?.contains(event?.target)) {
                trackGA4Event<GA4.ConsentBannerEvent>({
                    event: 'consent_banner',
                    clickConsentBanner: 'manage options',
                });
            }

            if (preferenceAcceptBtn?.contains(event?.target)) {
                resetPrivacyPrefObj();
                trackGA4Event<GA4.UpdatePrivacyPreferencesEvent>({
                    event: 'update_privacy_preferences',
                    privacyPref: { acceptAll: true },
                });
            }

            if (preferenceRejectBtn?.contains(event?.target)) {
                resetPrivacyPrefObj();
                trackGA4Event<GA4.UpdatePrivacyPreferencesEvent>({
                    event: 'update_privacy_preferences',
                    privacyPref: { rejectAll: true },
                });
            }

            if (toggledNob) {
                const label = toggledNob.nextElementSibling;
                const input = label?.parentElement.previousElementSibling;
                const isToggledOn = !input.checked;

                if (label && label.classList.contains('ot-label-txt')) {
                    const labelValue = label.textContent.trim();
                    defaultPreferenceOptions[preferenceOptionsLookup[labelValue]] = isToggledOn;
                }
            }

            if (preferenceSaveBtn?.contains(event?.target)) {
                resetPrivacyPrefObj();
                const isRejectedAll = Object.values(defaultPreferenceOptions).every(
                    (value) => value === false
                );

                trackGA4Event<GA4.UpdatePrivacyPreferencesEvent>({
                    event: 'update_privacy_preferences',
                    privacyPref: isRejectedAll
                        ? { rejectAll: true }
                        : { ...defaultPreferenceOptions },
                });
            }
        }
    });
};

export default (window) => {
    trackingEventsOnOneTrust(window);
};
