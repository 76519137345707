import React, { PureComponent } from 'react';

import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import SideMenuItem from './side-menu-item-container';
import LogoutForm from '../logout-form';

import './side-menu-section.scss';
import { MenuItem } from './side-menu-data';

interface Props {
    inExperimentGTC2229?: boolean;
    isLoggedIn?: boolean;
    items?: MenuItem[];
    title?: string;
    onItemClick?: (id: string) => void;
    openDialog?: (e: React.MouseEvent) => void;
}

class SideMenuSection extends PureComponent<Props> {
    handleClick = (id: string) => () => {
        const { onItemClick } = this.props;
        onItemClick && onItemClick(id);
    };

    render() {
        const { isLoggedIn = false, items = [], title = '', openDialog, inExperimentGTC2229 = false } = this.props;

        return (
            <section className="side-menu-section" {...qaAttribute('side-menu-section')}>
                {title && <h2 className="side-menu-title">{title}</h2>}
                <div className="side-menu-sublist">
                    {items &&
                        items
                            .filter((item) => (isLoggedIn && item.loggedIn) || (!isLoggedIn && item.loggedOut) || (!item.loggedIn && !item.loggedOut))
                            .map((item) =>
                                item.id === 'logout' ? (
                                    <LogoutForm key={item.label} mobile />
                                ) : (
                                    <SideMenuItem
                                        key={item.id}
                                        eventAction={item.eventAction}
                                        eventLabel={item.eventLabel}
                                        icon="chevron-r"
                                        id={item.id}
                                        betaLabel={item.betaLabel}
                                        isBannerTakeoverShowing={item.isBannerTakeoverShowing}
                                        className={item.className}
                                        label={item.label}
                                        link={item.link}
                                        openDialog={openDialog}
                                        inExperimentGTC2229={inExperimentGTC2229}
                                        onClick={this.handleClick(item.id)}
                                    />
                                )
                            )}
                </div>
            </section>
        );
    }
}

export default SideMenuSection;
