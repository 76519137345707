import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import httpMetrics from '@gumtree/web-bff/src/metrics/modules/http-metrics';
import { getGrowthbookClientSide } from '@gumtree/shell/src/growthbook/init-growthbook';
import {
    GrowthBookFeature,
    LongLivedGrowthbookFeatures,
} from '@gumtree/shared/src/model/experiment';

import type { Index, KeywordSuggestionsResponse } from './suggestions-service';

// Define the callback type
type AxiosCallback = (error: AxiosError | null, response: AxiosResponse | null) => void;

// Create a wrapper function for Axios with callback
function axiosCallback(config: AxiosRequestConfig, callback: AxiosCallback): void {
    axios(config)
        .then((response) => callback(null, response))
        .catch((error) => callback(error, null));
}

function getG5SIndex(busId: number, timeStamp: string, sign: string): Index {
    return {
        search: (
            value: string,
            callback: (err: any, content: { hits: KeywordSuggestionsResponse }) => void
        ) => {
            const reqId = uuidv4();
            const startTime = new Date().getTime();

            const isUsingG5SDirect =
                !!getGrowthbookClientSide() &&
                getGrowthbookClientSide().isOn(GrowthBookFeature.G5S_DIRECT);

            const url = isUsingG5SDirect
                ? 'https://suggestion.ok.com/api/searchSug'
                : '/external-api/search-suggestion';

            axiosCallback(
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    withCredentials: false, // Prevent cookies by default
                    url,
                    data: {
                        busId,
                        timeStamp,
                        sign,
                        completion: value,
                        reqId,
                    },
                },
                (error, response) => {
                    let responseStatus = response?.status;

                    if (!responseStatus) {
                        responseStatus = error?.response?.status;
                    }

                    recordHttpMetrics(responseStatus, startTime);

                    if (error || !response || !response.data) {
                        callback(error, { hits: [] });
                    } else {
                        callback(error, response.data);
                    }
                }
            );
        },
    };
}

function recordHttpMetrics(statusCode, startTime) {
    const timeTaken = new Date().getTime() - startTime;

    const isTrackingG5SMetrics =
        !!getGrowthbookClientSide() &&
        getGrowthbookClientSide().isOn(LongLivedGrowthbookFeatures.G5S_METRICS_TRACKING);

    const isUsingG5SDirect =
        !!getGrowthbookClientSide() && getGrowthbookClientSide().isOn(GrowthBookFeature.G5S_DIRECT);

    if (isTrackingG5SMetrics) {
        httpMetrics.registerHttpEvent(
            isUsingG5SDirect ? '58_search' : '58_search_proxy',
            'POST',
            isUsingG5SDirect ? '/api/searchSug' : '/external_api/search_suggestion',
            statusCode,
            timeTaken
        );
    }
}

class SuggestionsServiceG5S {
    private index: Index;

    constructor(busId: number, timeStamp: string, sign: string) {
        this.index = getG5SIndex(busId, timeStamp, sign);
    }

    search(value: string): Promise<KeywordSuggestionsResponse> {
        return new Promise((resolve, reject) => {
            this.index.search(value, (err, content = { hits: [] }) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(content.hits as KeywordSuggestionsResponse);
                }
            });
        });
    }
}

export const getNewInstance = (
    busId: number,
    timeStamp: string,
    sign: string
): SuggestionsServiceG5S => {
    return new SuggestionsServiceG5S(busId, timeStamp, sign);
};

export default SuggestionsServiceG5S;
