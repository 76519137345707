import styled from '@emotion/styled';
import {
    colors,
    gutterSizes,
    fontSizes,
    mediaQuery,
    breakpoints,
} from '@gumtree/ui-library/src/base/theme';
import FacebookLogoSvg from '@gumtree/shell/src/footer/social-icons/facebook-logo.svg';
import FacebookLogoPng from '@gumtree/ui-library/src/assets/Facebook_Logo_Primary.png';

const shouldForwardProp = (p: string) => !['reviewIsOpen', 'isHidden'].includes(p);

export const StyledSocialFormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${gutterSizes.large18};

    button {
        width: 310px;
    }
`;

export const StyledErrorBox = styled.div`
    background-color: ${colors.branch10};
    padding: ${gutterSizes.medium};
`;

export const StyledErrorTitle = styled.div`
    display: flex;
    align-items: center;
    gap: ${gutterSizes.small};
    margin-bottom: ${gutterSizes.small};
    color: ${colors.red};
    font-size: ${fontSizes.large};
`;

export const StyledErrorDescription = styled.span`
    font-size: ${fontSizes.base};
`;

export const StyledGoogleButtonWrapper = styled('div', {
    shouldForwardProp,
})<{
    isLoginPage: boolean;
}>`
    min-width: auto !important;

    ${({ isLoginPage }) =>
        isLoginPage
            ? `

        ${mediaQuery.from(breakpoints.xxsmall)} {
            width: 260px !important;
        }

        ${mediaQuery.from(breakpoints.medium)} {
            width: 400px !important;
        }
    `: `
    width: 400 !important;
    ${mediaQuery.between(breakpoints.xsmall, breakpoints.medium)} {
        width: 310px !important;
    }
`};
`;

export const StyledFacebookButton = styled('button', {
    shouldForwardProp,
})<{
    isLoginPage: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 400px;
    padding: ${gutterSizes.base} ${gutterSizes.medium};
    background: ${colors.facebook};
    border: 1px solid ${colors.facebook};
    border-radius: 2px;
    font-size: ${fontSizes.medium};
    color: ${colors.white};
    cursor: pointer;

    ${({ isLoginPage }) =>
        isLoginPage
            ? `

        ${mediaQuery.from(breakpoints.xxsmall)} {
            width: 260px !important;
        }

        ${mediaQuery.from(breakpoints.medium)} {
            width: 400px !important;
        }
    `
            : `
            width: 400 !important;
        ${mediaQuery.between(breakpoints.xsmall, breakpoints.medium)} {
            width: 310px !important;
        }
    `};

    &:hover {
        background-color: ${colors.bark};
    }
`;

export const StyledFacebookLogoPng = styled.div`
    background-image: url('${FacebookLogoPng}');
    background-size: cover;
    height: 24px;
    width: 24px;
    margin-right: ${gutterSizes.base};
`;

export const StyledFacebookLogoSvg = styled(FacebookLogoSvg)`
    position: absolute;
    top: 50%;
    left: ${gutterSizes.medium};
    transform: translateY(-50%);
`;
