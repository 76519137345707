import MetricsRegistry from '../metrics-registry';

class HttpMetrics extends MetricsRegistry {
    registerHttpEvent(serviceName, httpMethod, path, status, timeTaken, extraData = {}) {
        const eventName = constructLabelName(serviceName, httpMethod, path);

        if (typeof window !== 'undefined') {
            this.registerClientSideHistogramEvent(`${eventName}`, timeTaken, {
                status,
                calledFrom: 'client',
                ...extraData,
            });
        } else {
            this.registerServerSideHistogramEvent(`${eventName}`, timeTaken, {
                status,
                calledFrom: 'server',
                ...extraData,
            });
        }
    }

    getModuleName() {
        return 'http';
    }

    getCountersToRegister() {
        return [];
    }

    getHistogramsToRegister() {
        const validPaths = [
            { serviceName: 'seller', method: 'GET', path: '/postad' },
            { serviceName: 'seller', method: 'POST', path: '/postad' },
            { serviceName: 'seller', method: 'GET', path: '/postad/:id' },
            { serviceName: 'seller', method: 'POST', path: '/postad/:id' },
            { serviceName: 'seller', method: 'POST', path: '/postad/:id/images' },
            { serviceName: 'seller', method: 'POST', path: '/postad/:id/images/order' },
            { serviceName: 'seller', method: 'POST', path: '/postad/:id/images/delete' },
            { serviceName: 'seller', method: 'GET', path: '/postad/:id/bumpup' },
            { serviceName: 'seller', method: 'GET', path: '/api/category/suggest' },
            { serviceName: 'seller', method: 'GET', path: '/ajax/category/children' },
            { serviceName: 'seller', method: 'GET', path: '/ajax/vrn' },
            { serviceName: 'seller', method: 'GET', path: '/manage/ads/advert/:id' },
            { serviceName: 'seller', method: 'GET', path: '/conversations/extended' },
            { serviceName: 'buyer', method: 'GET', path: '/ajax/location/children' },
            { serviceName: 'buyer', method: 'GET', path: '/ajax/location/dropdown' },
            { serviceName: 'madgex_proxy', method: 'GET', path: '/:proxy' },
            { serviceName: 'madgex_proxy', method: 'POST', path: '/:proxy' },
            { serviceName: 'growthbook', method: 'GET', path: '/api/features' },
            {
                serviceName: '58_search_proxy',
                method: 'POST',
                path: '/external_api/search_suggestion',
            },
            {
                serviceName: '58_search',
                method: 'POST',
                path: '/api/searchSug',
            },
        ];

        return validPaths.map((path) => {
            return {
                name: constructLabelName(path.serviceName, path.method, path.path),
                labelNames: ['httpMethod', 'status', 'timeTaken', 'calledFrom', 'appViewDevice'],
                buckets: require('prom-client').linearBuckets(0, 100, 40),
            };
        });
    }
}

function constructLabelName(serviceName, method, path) {
    return `request_${serviceName}_${method}_${sanitizePath(path)}_milliseconds`;
}

function sanitizePath(path) {
    return path.replaceAll('/', '_').replaceAll(':', '');
}

export default new HttpMetrics();
