// Fix "process is not defined" in dev
import algoliasearch from 'algoliasearch/lite';

const APP_ID = 'D8YQWJ2C1Z';

let algolia;

export type KeywordSuggestionsResponse = {
    completion: string;
    categories: {
        id: string;
        canonicalName: string;
        localizedNames?: {
            en_GB: string;
        };
        depth: string;
        pathToRoot: {
            localizedNames: {
                en_GB: string;
            };
            canonicalName: string;
            id: string;
        }[];
    }[];
    wordLength: string;
    _highlightResult: {
        completion: {
            matchLevel: string;
            fullyHighlighted: boolean;
            matchedWords: string[];
        };
    };
}[];

export interface Index {
    search: (
        value: string,
        callback: (err: any, content: { hits: KeywordSuggestionsResponse }) => void
    ) => void;
}

export type SimplifiedSuggestion = {
    name: string;
    categorySeoName: string;
};

export const getIndex = (isQa: boolean): string => {
    return isQa ? 'gtuk_qa_tsa' : 'gtuk_production_tsa';
};

class SuggestionsService {
    private index: Index;

    constructor(indexName: string, algoliaKey: string) {
        if (!algolia) {
            algolia = algoliasearch(APP_ID, algoliaKey, {});
        }

        this.index = algolia.initIndex(indexName);
    }

    static convertToSimplifiedSuggestions = (
        suggestions: KeywordSuggestionsResponse
    ): SimplifiedSuggestion[] =>
        suggestions.map(({ completion: name, categories }) => {
            const categorySeoName = categories[0]?.canonicalName;
            return {
                name,
                categorySeoName,
            };
        });

    static removeSuggestion = (
        suggestions: SimplifiedSuggestion[],
        suggestionToRemove: string
    ): SimplifiedSuggestion[] =>
        suggestions.filter((suggestion) => suggestion.name !== suggestionToRemove);

    search(value: string): Promise<KeywordSuggestionsResponse> {
        return new Promise((resolve, reject) => {
            this.index.search(value, (err, content = { hits: [] }) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(content.hits as KeywordSuggestionsResponse);
                }
            });
        });
    }
}

// Factory method to help with mocking
export const getNewInstance = (indexName: string, algoliaKey: string) => {
    return new SuggestionsService(indexName, algoliaKey);
};

export default SuggestionsService;
