import { pathToRegexp } from 'path-to-regexp';
import brpRoutes from './brp-routes-subset.json';

export const pageNames = {
    FORGOTTEN_PASSWORD: 'forgotten-password',
    MANAGE_ACCOUNT: 'manage-account',
    RESET_PASSWORD: 'reset-password',
    MANAGE_MY_ADS: 'manage-my-ads',
    PROMOTE_AD: 'promote-ad',
    REGISTER: 'register',
    LOGIN: 'login',
    PROFILE: 'profile',
    HOME: 'home',
    RECRUITER_PROFILE: 'recruiter-profile',
    SYI: 'syi',
    SRP: 'srp',
    SELL_MY_CAR: 'sell-my-car',
    VIP: 'vip',
    JOB_PRICING: 'job-pricing',
    SERVER_500: 'error-500',
    NOT_FOUND: 'error-404',
    PRICE_GUIDANCE: 'price-guidance',
    MESSAGE_CENTRE: 'message-centre',
    REPLY_CONFIRMATION: 'reply-confirmation',
    STATIC_PAGES: 'static-pages',
    SELLER_ADS: 'sellerads',
    BUMP_UP: 'bump-up',
    CARS: 'cars',
} as const;

export type RoutingPageType = typeof pageNames[keyof typeof pageNames];

type Path = string | RegExp;

interface Route {
    path: Path;
    package: RoutingPageType;
    exact: boolean;
}

export const brpRoutesRegex = new RegExp(`^\\/(${brpRoutes.join('|')})(\\/(\\w|-)*)*`);

export const routeMap: Route[] = [
    {
        path: '/forgotten-password',
        package: pageNames.FORGOTTEN_PASSWORD,
        exact: false,
    },
    {
        path: '/manage-account',
        package: pageNames.MANAGE_ACCOUNT,
        exact: false,
    },
    {
        path: '/reset-password',
        package: pageNames.RESET_PASSWORD,
        exact: false,
    },
    {
        path: '/manage/ads',
        package: pageNames.MANAGE_MY_ADS,
        exact: false,
    },
    {
        path: '/create-account',
        package: pageNames.REGISTER,
        exact: false,
    },
    {
        path: '/login',
        package: pageNames.LOGIN,
        exact: false,
    },
    {
        path: '/profile/account/:accountId',
        package: pageNames.PROFILE,
        exact: false,
    },
    {
        path: new RegExp('/manage/messages(.*)'),
        package: pageNames.MESSAGE_CENTRE,
        exact: false,
    },
    {
        path: new RegExp('/reply-confirmation/(.*)'),
        package: pageNames.REPLY_CONFIRMATION,
        exact: false,
    },
    {
        path: '/sellerads/:sellerId',
        package: pageNames.SELLER_ADS,
        exact: false,
    },
    {
        path: '/postad/bumpup',
        package: pageNames.BUMP_UP,
        exact: false,
    },
    {
        path: '/search',
        package: pageNames.SRP,
        exact: true,
    },
    {
        path: '/postad/promote',
        package: pageNames.PROMOTE_AD,
        exact: true,
    },
    {
        path: new RegExp('/termsofuse(.*)'),
        package: pageNames.STATIC_PAGES,
        exact: false,
    },
    {
        path: new RegExp('/privacy_policy(.*)'),
        package: pageNames.STATIC_PAGES,
        exact: false,
    },
    {
        path: '/',
        package: pageNames.HOME,
        exact: true,
    },
    {
        path: '/london',
        package: pageNames.HOME,
        exact: true,
    },
    {
        path: '/jobs/cmp/:company',
        package: pageNames.RECRUITER_PROFILE,
        exact: false,
    },
    {
        path: new RegExp('/postad/(category|create|edit|repost)'),
        package: pageNames.SYI,
        exact: false,
    },
    {
        path: '/sell-my-car',
        package: pageNames.SELL_MY_CAR,
        exact: true,
    },
    {
        path: '/vip',
        package: pageNames.VIP,
        exact: true,
    },
    {
        path: '/postad/job-pricing',
        package: pageNames.JOB_PRICING,
        exact: false,
    },
    {
        path: '/p/:category/:title/:adId',
        package: pageNames.VIP,
        exact: false,
    },
    {
        path: '/error/404',
        package: pageNames.NOT_FOUND,
        exact: true,
    },
    {
        path: '/price-guidance',
        package: pageNames.PRICE_GUIDANCE,
        exact: true,
    },
    {
        path: '/cars-vans-motorbikes/cars',
        package: pageNames.CARS,
        exact: true,
    },
    {
        path: brpRoutesRegex,
        package: pageNames.SRP,
        exact: false,
    },
    {
        path: '/cookies',
        package: pageNames.STATIC_PAGES,
        exact: true,
    },
    {
        path: '/categories',
        package: pageNames.STATIC_PAGES,
        exact: true,
    },
    {
        path: '/categories/:categoryName',
        package: pageNames.STATIC_PAGES,
        exact: false,
    },
    {
        path: '/modern_slavery',
        package: pageNames.STATIC_PAGES,
        exact: true,
    },
];

export const getAppRoutes = (routes = routeMap): Path[] => routes.map((route) => route.path);

export const findPageTypeBy = (path: string): RoutingPageType =>
    routeMap.find((route) =>
        route.exact ? route.path === path : pathToRegexp(route.path).test(path)
    )?.package || pageNames.NOT_FOUND;
