export const seoNameToId = {
    'cars-vans-motorbikes': 2551,
    'for-sale': 2549,
    'flats-houses': 10201,
    jobs: 2553,
    'business-services': 2554,
    community: 2550,
    pets: 2526,
} as const;

export const l2SeoNameToId = {
    'accounting-jobs': 12226,
    'agriculture-and-farming-jobs': 12213,
    'animals-jobs': 12214,
    'artists-theatres': 5210,
    'arts-and-heritage-jobs': 12273,
    'baby-kids-stuff': 2497,
    birds: 9395,
    'building-home-removal-services': 2545,
    'business-office-services': 11237,
    'cameras-studio-equipment': 10972,
    'campervans-motorhomes': 1028,
    caravans: 4707,
    cars: 9311,
    'cars-wanted': 10301,
    cats: 9395,
    'cds-dvds-games-books': 2517,
    'childcare-jobs': 12232,
    'childcare-services': 11264,
    'christmas-decorations': 11979,
    classes: 130,
    clothing: 2496,
    'clothing-services': 11360,
    commercial: 12172,
    'computers-software': 2515,
    'computing-it-jobs': 12250,
    'construction-jobs': 12209,
    'customer-service-call-center-jobs': 12227,
    'diy-tools-materials': 10683,
    dogs: 9395,
    'driving-warehouse-jobs': 12204,
    'engineering-jobs': 12279,
    'entertainment-services': 4923,
    'events-gigs-nightlife': 21,
    exotics: 9395,
    'financial-services-jobs': 12302,
    fish: 9395,
    'food-drink-services': 11300,
    freebies: 120,
    'garage-parking': 12178,
    'gardening-landscaping-jobs': 12217,
    'goods-supplier-retailer-services': 11252,
    'groups-associations': 5213,
    'health-beauty': 10634,
    'health-beauty-jobs': 12236,
    'health-beauty-services': 4919,
    'healthcare-medicine-pharmaceutical-jobs': 12294,
    'holiday-rentals': 12176,
    'home-garden': 2514,
    'home-swap': 1040,
    'horses-ponies': 9395,
    'hospitality-catering-jobs': 12219,
    'house-clearance': 153,
    'housekeeping-cleaning-jobs': 12201,
    'international-property-for-sale': 12177,
    'kitchen-appliances': 2485,
    'leisure-and-tourism-jobs': 12288,
    'lost-found-stuff': 18,
    'manufacturing-jobs': 12299,
    'marketing-advertising-and-pr-jobs': 12262,
    'media-design-creative-jobs': 12245,
    'miscellaneous-goods': 2524,
    'motorbikes-scooters': 10442,
    'motoring-services': 11282,
    'motors-accessories': 10016,
    'motors-parts': 9314,
    'music-bands-musicians-djs': 5185,
    'music-instruments': 2495,
    'office-furniture-equipment': 4626,
    'other-vehicles': 11983,
    'paralegal-legal-jobs': 12267,
    'performing-arts-jobs': 12303,
    'pet-equipment-accessories': 1018,
    'pet-services-supplies': 11367,
    'pets-for-sale': 9395,
    'pets-missing-lost-found': 9395,
    phones: 4655,
    'plant-tractors': 10021,
    'property-for-sale': 12182,
    'property-shipping-services': 2543,
    'property-to-rent': 12181,
    'property-to-share': 12183,
    'property-wanted': 12175,
    'purchasing-and-procurement-jobs': 12292,
    'recruitment-resourcing-jobs': 12293,
    'retail-jobs': 12235,
    'rideshare-car-pooling': 544,
    'sales-customer-service-jobs': 12227,
    'scientific-and-research-jobs': 12285,
    'secretary-pa-jobs': 12228,
    'security-jobs': 12208,
    'skills-language-swap': 1583,
    'small-furries': 9395,
    'social-work-jobs': 12242,
    'sport-fitness-and-leisure-jobs': 12249,
    'sports-leisure-travel': 2516,
    'sports-teams-partners': 6,
    'stereos-audio': 2518,
    'stuff-wanted': 2525,
    'swap-shop': 1039,
    'tax-money-visa-services': 2542,
    'teaching-nursery-jobs': 12255,
    'telecoms-computer-services': 2544,
    tickets: 2521,
    'training-hr-jobs': 12287,
    'transport-logistics-and-delivery-jobs': 12268,
    'transport-services': 11372,
    'travel-services-tour-services': 2527,
    'travel-travel-partners': 7,
    trucks: 10022,
    'tuition-lessons': 2499,
    'tv-dvd-cameras': 2517,
    vans: 94,
    'video-games-consoles': 2488,
    'volunteer-charity-work-jobs': 12283,
    'wedding-services': 11347,
} as const;
