import React from 'react';
import styled from '@emotion/styled';

import { Button, Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { fontSizes } from '@gumtree/ui-library/src/base/theme';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { NavBarButtonOverride } from '../nav-bar/common.style';

export const StyledButton = styled(Button)`
    && {
        ${NavBarButtonOverride}
    }

    &.inGTC2229 {
        .icon {
            font-size: ${fontSizes.pgLarge};
        }
    }
`;

interface SideNavButtonProps {
    dark?: boolean;
    onClick?: () => void;
    inExperimentGTC2229?: boolean;
}

export const SideNavButton: React.FC<SideNavButtonProps> = ({
    dark = false,
    onClick = () => {},
    inExperimentGTC2229 = false,
}) => (
    <StyledButton
        bottomLabel
        className={inExperimentGTC2229 ? 'inGTC2229' : ''}
        dark={dark}
        icon={<Icon type="burger" />}
        label="Menu"
        onClick={() => {
            onClick();
            trackGA4Event<GA4.ClickMobileBurgerMenu>({
                event: 'click_mobile_burger_menu_icon',
                clickMenuAction: 'open',
                breakpointSize: 'mobile',
            });
            trackV2('HeaderMenuSelect');
        }}
        {...qaAttribute('side-nav-button-mobile')}
    />
);

export default SideNavButton;
