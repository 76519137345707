import fetch from 'isomorphic-fetch';
import { encode } from 'html-entities';

import { redirectToUrl } from '@gumtree/ui-library/src/utils/browser-service';
import {
    getIndex,
    getNewInstance as getNewAlgoliaInstance,
    KeywordSuggestionsResponse,
} from '@gumtree/ui-library/src/utils/suggestions-service';
import { getNewInstance as getNewG5SInstance } from '@gumtree/ui-library/src/utils/suggestions-service-g5s';
import { RecentSearchesItem, save as saveSearches } from './recent-service/recent-searches-service';
import { save as saveLocations } from './recent-service/recent-locations-service';
import { G5SParams } from './reducers/g5s-params';

interface SuggestionService {
    search: (value: string) => Promise<KeywordSuggestionsResponse>;
}

let internalSuggestionsService: SuggestionService | undefined;
let isSubmitSearchCalled = false; // Browser seems to have a bug that calls submitSearch() a second time after a click on search item. This ensures submitSearch() is only ever called once

export const getSuggestionsService = () => internalSuggestionsService;

export const setIsSubmitSearchCalled = (val) => (isSubmitSearchCalled = val);

export const setupSuggestionsService = (
    isQa = false,
    algoliaKey: string,
    isUseG5S: boolean,
    g5SParams: G5SParams
) => {
    internalSuggestionsService = isUseG5S
        ? getNewG5SInstance(g5SParams.busId, g5SParams.timeStamp, g5SParams.sign)
        : getNewAlgoliaInstance(getIndex(isQa), algoliaKey);
};

export const fetchLocationSuggestions = (
    value: string
): Promise<{ name: string; type: string }[]> =>
    fetch(`/ajax/location/prefix?input=${value}`)
        .then((response) => response.json())
        .then((response) => response.locationList as { name: string; type: string }[]);

export type KeywordSuggestion = {
    name: string;
    highlight: string;
    category?: string;
    categoryDisplayName?: string;
    searchOptionsExactMatch?: boolean;
};

export const transformResponseToSuggestions = (
    results: KeywordSuggestionsResponse
): KeywordSuggestion[] => {
    const getHighlight = (suggestion: KeywordSuggestionsResponse[number]) => {
        return suggestion?._highlightResult?.completion?.matchedWords?.[0] || '';
    };

    const [first, ...rest] = results;

    return [
        ...(first ? [{ name: first.completion, highlight: getHighlight(first) }] : []),
        ...(first?.categories?.map(({ canonicalName, localizedNames }) => ({
            name: first.completion,
            highlight: getHighlight(first),
            category: canonicalName,
            categoryDisplayName: localizedNames?.en_GB,
        })) || []),
        ...rest.map((suggestion) => ({
            name: suggestion.completion,
            highlight: getHighlight(suggestion),
        })),
    ];
};

export const fetchKeywordSuggestions = async (value: string): Promise<KeywordSuggestion[]> => {
    const suggestionsService = getSuggestionsService();

    return !suggestionsService
        ? Promise.resolve([])
        : suggestionsService
              .search(value)
              .then((results: KeywordSuggestionsResponse = []) =>
                  transformResponseToSuggestions(results)
              );
};

export const submitSearch = ({
    category = 'all',
    categoryDisplayName,
    keyword,
    location,
    searchOptionsExactMatch,
    additionalParams = {},
    requireKeywordCorrection,
    keywordPopulatedBy,
}: {
    category: string | undefined;
    categoryDisplayName: string | undefined;
    keyword: string;
    location: string;
    searchOptionsExactMatch: boolean;
    additionalParams?: Record<string, string | boolean | number>;
    requireKeywordCorrection: boolean;
    keywordPopulatedBy: GA4.SubmitListingSearchEvent['search']['keywordPopulatedBy'];
}) => {
    if (isSubmitSearchCalled) {
        return;
    }

    isSubmitSearchCalled = true;

    const queryParams: string[] = [];

    if (category) {
        queryParams.push(`search_category=${category}`);
    }

    if (keyword) {
        queryParams.push(`q=${keyword}`);
        queryParams.push(`search_term_populated_by=${keywordPopulatedBy}`);

        if (requireKeywordCorrection) {
            queryParams.push(`keyword_correction=auto`);
        }

        const recentSearch: RecentSearchesItem = {
            name: keyword,
        };

        if (category) {
            recentSearch.category = category;
        }

        if (categoryDisplayName) {
            recentSearch.categoryDisplayName = categoryDisplayName;
        }

        if (searchOptionsExactMatch) {
            queryParams.push(`searchOptionsExactMatch=true`);
            recentSearch.searchOptionsExactMatch = true;
        }

        saveSearches(recentSearch);
    }

    if (location) {
        queryParams.push(`search_location=${encode(location)}`);

        saveLocations({ name: location });
    }

    const paramsKeys = Object.keys(additionalParams);

    if (paramsKeys.length) {
        paramsKeys.forEach((param) => {
            queryParams.push(`${param}=${additionalParams[param]}`);
        });
    }

    const searchUrl = `/search?${queryParams.join('&')}`;

    redirectToUrl(searchUrl);
};
