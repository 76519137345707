import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';
import { arrayIntersection } from '../../../util/array-intersection';

const appendExperiment = (
    abTests: string[] | undefined,
    pageType: PageType,
    device: Device
): string[] => {
    const conditionsMapping = [
        {
            device: ['desktop', 'tablet', 'mobile'],
            pageType: ['ResultsBrowse', 'ResultsSearch'],
            abTests: ['GTF-1068-A', 'GTF-1068-B'],
        },
        {
            device: ['desktop', 'tablet', 'mobile'],
            pageType: ['ResultsBrowse', 'ResultsSearch'],
            abTests: ['GTF-1827-A', 'GTF-1827-B'],
        },
    ];

    return conditionsMapping
        .filter(
            (condition) =>
                condition.device.includes(device) &&
                condition.pageType.includes(pageType) &&
                arrayIntersection(abTests || [], condition.abTests).length === 1
        )
        .map((condition) => arrayIntersection(abTests || [], condition.abTests)[0]);
};

export const buildTracingTag = (params: {
    device: Device;
    l1Category: L1Category;
    pageType: PageType;
    resultSize: number | undefined;
    abTests?: string[];
}) => {
    const { device, l1Category, pageType, resultSize, abTests } = params;

    const categoryCode = (l1Category === 'toplevel' ? 'all' : l1Category).replace(/ /g, '');
    const experimentCode = appendExperiment(abTests, pageType, device);
    let pageTypeCode: string = pageType;
    if (pageType === 'ResultsBrowse') {
        pageTypeCode = 'brp';
    } else if (pageType === 'ResultsSearch') {
        pageTypeCode = resultSize === 0 ? 'zsrp' : 'srp';
    }
    if (pageType === 'VIP') {
        pageTypeCode = 'vip';
    }
    const deviceCode = device && device.toLowerCase();
    const basicTracingTag = ['responsive', deviceCode, pageTypeCode, categoryCode].join('_');
    return experimentCode.length > 0
        ? `${basicTracingTag}_${experimentCode.join('_')}`
        : basicTracingTag;
};
