import { useDispatch } from 'react-redux';
import {
    setIsOpen,
    setType,
    setIsLogin,
    showForgotPasswordModal,
    type LoginModalType,
} from './reducers/common/login-modal';

export const useModal = () => {
    const dispatch = useDispatch();

    const openDialog = (event: React.MouseEvent, modalType: LoginModalType, showLogin: boolean) => {
        event.preventDefault();
        dispatch(setIsOpen(true));
        dispatch(setType(modalType));
        dispatch(setIsLogin(showLogin));
    };

    const closeDialog = () => {
        dispatch(setIsOpen(false));
        dispatch(showForgotPasswordModal({ value: false }));
    };

    return { openDialog, closeDialog };
};
