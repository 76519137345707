import React from 'react';
import { css } from '@emotion/react';

import { fontSizes, colorVariables } from '@gumtree/ui-library/src/base/theme';
import { trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';
import { l2SeoNameToId } from '@gumtree/ui-library/src/utils/seo-name-to-id';

export default function SubNavPanelSectionItem({
    href,
    label,
    seoName,
    sectionTitle,
    ...props
}: Props) {
    type L2SeoNameToIdKeys = keyof typeof l2SeoNameToId;

    return (
        <li {...props} css={liStyle}>
            <a
                onClick={() => {
                    trackGA4PreNav<GA4.ClickNavigationMenu>({
                        event: 'click_navigation_menu',
                        linkDomain: getDomainFromUrl(href),
                        linkText: label,
                        linkURL: href,
                        navigationCategory: seoName as L2SeoNameToIdKeys,
                        navigationCategoryId:
                            (seoName && l2SeoNameToId[seoName as L2SeoNameToIdKeys]) || undefined,
                        navigationHeader: sectionTitle,
                        navigationHierarchy: 'sub category',
                    });
                }}
                href={href}
                css={linkStyle}
            >
                {label}
            </a>
        </li>
    );
}
interface Props {
    href: string;
    label: string;
    seoName?: string;
    sectionTitle: NavRibbonSectionTitle;
}

export type NavRibbonSectionTitle = 'Browse by' | 'Discover more in our guides';

const liStyle = css`
    list-style-type: none;
    margin-bottom: 10px;
`;

const linkStyle = css`
    display: block;
    font-size: ${fontSizes.base};
    line-height: 22px;
    color: ${colorVariables.textNeutral};

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;
